import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { transformDataForSearch } from '../../util/parsers';

import Autosuggest from 'react-autosuggest';
import './styles.scss';

const getMeasureValue = measure => measure.benchmarkingBody;

const renderItem = item => (
  <div>{item.benchmarkingBody}</div>
);

function BenchmarkSearch(props) {
  const [ searchSafeQuestions, setSearchSafeQuestions ] = useState([]);
  const [ selectedSurvey, setSelectedSurvey] = useState('');
  const [ suggestions, setSuggestions] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const {
    questions,
    onSelectQuestion,
  } = props;

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) return [];

    return searchSafeQuestions.map(section => ({
      title: section.title,
      questions: section.questions.filter(question => 
        question.body.toLowerCase().includes(inputValue)
      )
    }))
    .filter(section => section.questions.length > 0); 
  }

  const getSectionSuggestions = section => {
    return section.questions;
  }

  useEffect(() => {
    setSearchSafeQuestions(transformDataForSearch(questions)); 
  }, [questions]);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  }

  const onSelectSuggestion = (e, {suggestion}) => {
    onSelectQuestion(suggestion);
    setInputValue('');
  }

  const onInputChange = (event, { newValue }) => {
    setInputValue(newValue);
  }

  const onSelectSurvey = (key) => {
    setSelectedSurvey(key);
  }

  const renderSectionTitle = section => {
    return (
      <strong>{section.title}</strong>
    );
  }

  const inputProps = {
    placeholder: 'Search for a benchmark',
    value: inputValue,
    onChange: onInputChange,
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getMeasureValue}
      onSuggestionSelected={onSelectSuggestion}
      renderSuggestion={renderItem}
      inputProps={inputProps}
      focusInputOnSuggestionClick={false}
      multiSection
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
    />
  );
}

export default BenchmarkSearch;
