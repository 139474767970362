import { get, post, put, del } from '../util/request';
import { apiUrl } from '../config/api';

export function getPoliciesForUser(email) {
  return new Promise((resolve, reject) => {
    get('/policies', {
      headers: {
        email: email,
      }
    })
      .then((policies) => {
        resolve(policies.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });  
  });
};

export function createNewPolicy(email) {
  return new Promise((resolve, reject) => {
    post('/policies', null, { email })
      .then((policies) => {
        resolve(policies.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });  
  });
};

export function getPolicy(policyId) {
  return new Promise((resolve, reject) => {
    get(`/policies/${policyId}`)
      .then((policy) => {
        resolve(policy.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function updatePolicy(policyId, updateBody) {
  return new Promise((resolve, reject) => {
    put(`/policies/${policyId}`, updateBody)
      .then((updatedPolicy) => {
        resolve(updatedPolicy.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function deletePolicy(policyId) {
  return new Promise((resolve, reject) => {
    del(`/policies/${policyId}`)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function enablePolicyComponentGroup(policyId, groupId) {
  return new Promise((resolve, reject) => {
    post(`/policies/${policyId}/component-group`, {
      groupId,
    })
      .then((updatedPolicy) => {
        resolve(updatedPolicy);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function disablePolicyComponentGroup(policyId, groupId) {
  console.log('POLICY ID', policyId);
  console.log('GROUP ID', groupId);

  return new Promise((resolve, reject) => {
    del(`/policies/${policyId}/component-group/${groupId}`)
      .then((updatedPolicy) => {
        resolve(updatedPolicy);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function updatePolicyComponent(policyComponentId, body) {
  return new Promise((resolve, reject) => {
    put(`/policycomponents/${policyComponentId}`, body)
      .then((updatedPolicyComponent) => {
        resolve(updatedPolicyComponent.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function exportPolicy(policyId) {
  window.open(`${apiUrl}/policies/${policyId}/export`); 
}

export function getPolicyChunks() {
  return new Promise((resolve, reject) => {
    get('/templates/default')
      .then((policyChunks) => {
        resolve(policyChunks.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}
