import { get, post, put } from '../util/request';

export function getQuestions(questions, groupId) {
  let requestOpts = {
    params: {},
  };
  if (questions) requestOpts.params = { questions };
  if (groupId) requestOpts.params.sortBySection = groupId;

  return new Promise((resolve, reject) => {
    get('/questions', requestOpts)
      .then((questions) => {
        resolve(questions.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });  
  });
};

export function updateQuestion(id, body) {
  return new Promise((resolve, reject) => {
    put(`/questions/${id}`, body)
      .then((updatedQuestion) => {
        resolve(updatedQuestion.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function updateQuestionOption(questionId, optionId, optionValue) {
  return new Promise((resolve, reject) => {
    put(`/questions/${questionId}/options/${optionId}`, {body: optionValue})
      .then((updatedQuestion) => {
        resolve(updatedQuestion.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}

export function getQuestionData(questionId, filters, email) {
  return new Promise((resolve, reject) => {
    get(`/questions/${questionId}/data`, 
    { 
      params: filters,
      headers: { email: email }
    }
    )
      .then((questionWithData) => {
        resolve(questionWithData.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });
}
