import React from 'react';
require('./styles.scss');

function AdminQuestionListItem({ question, onClick }) {

  const {
    body,
    qid,
  } = question;

  const onSelectQuestion = () => {
    onClick(question);
  }

  return (
    <div
      className="d-flex align-items-center admin-question-list-item mb-3"
      onClick={onSelectQuestion}
    > 
      <h4 className="mb-0 mr-3">{ qid }</h4>
      <p className="mb-0">{ body }</p>
    </div>
  );
}

export default AdminQuestionListItem;
