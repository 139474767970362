import React from 'react';
import classNames from 'classnames';

require('./styles.scss');

function MeasureGroupListItem({ activeMeasure, measure, onSelectMeasure }) {
  const { 
    _id,
    body,
    benchmarkingBody,
  } = measure;

  const onClickMeasureGroupListItem = () => {
    onSelectMeasure(_id);
  }

  const measureGroupListItemClasses = classNames(
    'measure-group-list-item',
    'd-flex',
    'align-items-center',
    'w-100',
    {
      selected: measure._id === activeMeasure._id,
    }
  );

  return (
    <div 
      className={measureGroupListItemClasses}
      onClick={onClickMeasureGroupListItem}
    >
      <i className="fal fa-angle-right mr-2" />{benchmarkingBody}
    </div>
  );
}

export default MeasureGroupListItem;
