import React, { Fragment, useState } from 'react';
import classNames from 'classnames';

import MeasureGroupListItem from '../MeasureGroupListItem';

require('./styles.scss');

function MeasureGroup({ activeMeasure, groupTitle, measures, onSelectMeasure }) {
  const [ selected, setSelected ] = useState(false);
  // If the active measure is in this group, set as open by default
  if ((!selected && measures.findIndex(measure => measure._id === activeMeasure._id) !== -1)) {
    setSelected(true); 
  }

  const measureGroupIconClasses = classNames(
    'fa',
    'mr-2',
    {
      'fa-caret-down': selected,
      'fa-caret-right': !selected,
    }
  );

  const onSelectMeasureItem = measureId => {
    onSelectMeasure(measureId);
  }

  return (
    <Fragment>
      <div onClick={ () => setSelected(!selected) } className="pb-2 measure-group">
        <i className={measureGroupIconClasses} />
        {groupTitle}
      </div>
      {selected &&
        <div className="pb-3 w-100">
            {measures.map(measure => (
              <MeasureGroupListItem
                activeMeasure={activeMeasure}
                measure={measure}
                onSelectMeasure={onSelectMeasureItem}
              />)
            )}
        </div>
      }
    </Fragment>
  );
}

export default MeasureGroup;
