import React from "react"
import Nav from "../Nav"
import { Jumbotron } from "react-bootstrap"


function Unauthorized() {
    return (
        <div className="portal">
            <Nav />
            <div className="container">
                <Jumbotron>
                    <h1>Unauthorized</h1>
                    <p>
                        Thank you for your interest in accessing our global benchmarking database!
            <br />
                        To learn more about participating in our studies, and to obtain database access,
                        please contact Worldwide ERC Research at <a href="mailto:research@worldwideerc.org" target="_blank" rel="noopener noreferrer">research@worldwideerc.org</a>.
            </p>
                </Jumbotron>
            </div>
        </div>
    )
}

export default Unauthorized