import { get, post, put } from '../util/request';

export function getFilterOptions() {
  return new Promise((resolve, reject) => {
    get('/admin/filters')
      .then((filterOptions) => {
        resolve(filterOptions.data);
      })
      .catch((err) => {
        reject(new Error(err));
      });  
  });
};

export function getAppletQuestion() {
  return new Promise((resolve, reject) => {
    get('/admin/appletquestion')
      .then(response => resolve(response.data))
      .catch((err) => {
        reject(new Error(err));
      });
  });
}
