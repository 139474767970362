const axios = require('axios');
const { apiUrl } = require('../config/api');

const getHeaders = () => {
  return new Promise((resolve, reject) => {
    return resolve({});
  });
}

const service = axios.create({
  baseURL: apiUrl,
  headers: getHeaders(),
});

export const get = (url, options) => {
  const opts = { ...options };
  return new Promise(async (resolve, reject) => {
    service.get(url, {
      ...opts, 
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export const post = (url, body, headers) => { 
  return new Promise((resolve, reject) => {
    service.post(url, body, { headers })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export const put = (url, body, options) => {
  const opts = { ...options }; 
  return new Promise(async(resolve, reject) => {
    service.put(url, body, {
      ...opts, 
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export const del = (url, options) => {
  const opts = { ...options };
  return new Promise(async (resolve, reject) => {
    service.delete(url, {
      ...opts,
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}
