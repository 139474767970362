import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import TrackVisibility from 'react-on-screen';

import PolicyComponentBenchmarks from '../PolicyComponentBenchmarks';

require('./styles.scss');

function PolicyComponent(props){
  const { answer, question } = props;
  const opts = question.options.map((option) => {
    return {
      value: option._id, 
      label: option.body 
    }
  });

  const [selectedOption, setSelectedOption] = useState(() => {
    if (!props.answer) return undefined;
    return opts.find(opt => opt.value === props.answer.option);
  });

  if (answer) console.log('OPTS', opts);
  if (answer) console.log('ANSWER', answer);

  const onChangeValue = (selection) => {
    console.log('selection', selection);
    setSelectedOption(selection);
    props.onChangeValue(question, selection);
  }

  return (
    <div className="row p-3 border-bottom policy-component align-items-center">
      <div className="col-6 align-self-start mt-5">
        <h6 className="mb-4">{question.body}</h6>
        <span>
          <Select
            options={opts}
            onChange={onChangeValue}
            value={selectedOption}
          />  
        </span>
      </div>
      <div className="col-6">
        <TrackVisibility partialVisibility>
            {({isVisible}) => isVisible && <PolicyComponentBenchmarks question={question} filters={props.filters} />}
        </TrackVisibility>
      </div>
    </div>
  );
}

export default PolicyComponent;
