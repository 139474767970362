import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/erc-logo.png';

function Nav(props) {
  return (
    <div className="row">
      <div className="col-12">
        <nav className="bg-primary px-3 py-3 w-100 text-white d-flex align-items-center">
          <Link to='explorer' className="mr-auto">
            <img src={logo} alt="ERC Benchmarking Portal" width="200" />
          </Link>
          {props.includesPolicyExport &&
            <button className="btn btn-light mr-3" onClick={() => props.onExportPolicy()}>Export Policy</button>
          }
          <a href="https://worldwideerc.org" className="text-white">Back to Worldwide ERC</a>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
