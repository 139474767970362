import React, { useState } from 'react';

import BenchmarkDetail from '../BenchmarkDetail';
import BenchmarkSearch from '../BenchmarkSearch';
import MeasureGroup from '../MeasureGroup';
import Nav from '../Nav';
import SurveySelect from '../SurveySelect';
import TOSModal from '../TOSModal';
import './styles.scss';

import { getQuestions } from '../../services/question.service';

function Portal(props) {

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [flattenedQuestions, setFlattenedQuestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  if (questions.length === 0 ) {
    getQuestions()
      .then(questions => {
        const flattenedQuestions = [];
        questions.forEach(section => flattenedQuestions.push(section.questions));
        setQuestions(questions);
        setFlattenedQuestions([].concat.apply([], flattenedQuestions)); //this is flattening the array
        setActiveQuestion(flattenedQuestions[0][0]);
      })
      .catch(err => console.log(err));
  }

  const onSetActiveQuestion = (questionId) => {
    const activeQuestion = flattenedQuestions.find(question => question._id === questionId);    
    setActiveQuestion(activeQuestion);
  }

  const onSearchQuestions = (query) => {
    setIsSearching(true);
    const searchResults = flattenedQuestions.map(q => q.body.includes(query));     
    setSearchResults(searchResults);
  }

  return (
    <div className="portal"> 
      <TOSModal 
        hasAcceptedTerms={hasAcceptedTerms}
        onAcceptTerms={() => setHasAcceptedTerms(true)}
      />
      <Nav />
      <div className="container-fluid">
        <div className="row fixed-height-row">
          <div className="col-3 navigation scrolling-column">
            <div className="row">
              <div className="col pt-4 bg-white">
                <h4>Explore benchmarks</h4>
                <SurveySelect /> 
              </div>
            </div>
            <div className="row mt-1 pt-3 bg-white">
              <div className="w-100">
                { !isSearching && questions.map((questionGroup, index) => {
                  return (
                    <MeasureGroup
                      key={questionGroup.id}
                      groupTitle={questionGroup.questions[0].populatedCategory[0].title}
                      measures={questionGroup.questions}
                      activeMeasure={activeQuestion}
                      onSelectMeasure={onSetActiveQuestion}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-9 bg-lightgray pt-0 pl-0 scrolling-column">
            <div className="w-100 px-3 py-4">
              <BenchmarkSearch 
                questions={questions}
                onSelectQuestion={(question) => setActiveQuestion(question)}
                onCancelSearch={() => setIsSearching(false)}
                onSearch={onSearchQuestions}
              />
            </div>
            <BenchmarkDetail 
              activeQuestion={activeQuestion}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portal;
