import React from 'react';
import Select from 'react-select';

require('./styles.scss');

function DropdownFilter({className = "", formatter, label, options, onChange, selected, requiresSort, sortMethod}) {
  const onSelectOption = (selectedValues, action) => {
    onChange(selectedValues);
  }

  const onResetOption = (value) => {
    onChange(null);
  }

  //TODO: if the user doesn't have access to the filters, add the `isDisabled` prop below
   
  if (requiresSort) {
    sortMethod(options);
  }

  const classes = className.concat(" dropdown-filter");

  return (
    <Select
      value={selected}
      onChange={onSelectOption}
      options={options}
      isMulti
      className={classes}
      placeholder={label}
    />
  );
}

export default DropdownFilter;
