import React from 'react';
import { OverlayTrigger, Popover } from  'react-bootstrap';

require('./styles.scss');

const ProgramIndicator = (props) => {
  const popover = (
    <Popover id="program-popover">
      <Popover.Content>
        <ul>
          { props.policies.map(policy => (
            <li>{policy.name}</li>
          ))}
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <div className="program-indicator ml-5 d-flex align-items-center text-center">
          {props.policies.length} of your programs 
      </div>   
    </OverlayTrigger>
  );
}

export default ProgramIndicator;
