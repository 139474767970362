import React from 'react';

import './styles.scss';

function DashboardItem({ title, description, icon }) {
  return (
    <div className="text-center border p-5">
      <i className={`fad ${icon} fa-4x`} />
      <h3>{title}</h3> 
      <p>{description}</p>
    </div>
  );
}

export default DashboardItem;
