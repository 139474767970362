import React, { useEffect, useState } from 'react';

import DropdownFilter from '../DropdownFilter';

import  { getFilterOptions } from '../../services/admin.service';

const PolicyBenchmarkFilters = (props) => {
  const [industryOptions, setIndustryOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [revenueOptions, setRevenueOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [HQOptions, setHQOptions] = useState([]);
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [employeeStatusOptions, setEmployeeStatusOptions] = useState([]);
  const [requestedOptions, setRequestedOptions] = useState([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    getFilterOptions()
      .then(opts => {
        setIndustryOptions(opts.industry);
        setProgramOptions(opts.programType)
        setRevenueOptions(opts.revenue);
        setSizeOptions(opts.size);
        setHQOptions(opts.hq);
        setJobLevelOptions(opts.jobLevel);
        setEmployeeStatusOptions(opts.employeeStatus);
        setRequestedOptions(opts.requested);
        setEmployeeTypeOptions(opts.employeeType);
      })
      .catch(e => console.log(e));

    if (process.env.NODE_ENV === 'production') {
      //var roles = user["https://www.worldwideerc.org/roles"] || []
      //setCanUseFilters(roles.includes(userRoles.GMDA))
    }
  }, []);

  const onChangeFilter = (filterName, values) => {
    const arrayOfValues = values.map(v => v.value);
    const updatedFilters = {
      ...filters,
      [filterName]: arrayOfValues,
    };

    setFilters(updatedFilters);
    props.onChangeFilters(updatedFilters);
  }

  return (
    <div className="mt-4">
      <h6>Set benchmarks based on:</h6>
      <DropdownFilter
        label='All Policy Types'
        options={programOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('policyType', values)}
      />
      <DropdownFilter
        label='All Industries'
        options={industryOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('industry', values)}
      />
      <DropdownFilter
        label="All Revenues"
        options={revenueOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('revenue', values)}
      />
      <DropdownFilter
        label='All Headcounts'
        options={sizeOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('size', values)}
      /> 
      <DropdownFilter
        label='All HQ Locations'
        options={HQOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('HQ', values)}
      />
      <DropdownFilter
        label='All Job Levels'
        options={jobLevelOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('jobLevel', values)}
      />
      <DropdownFilter
        label='All Employee Statuses'
        options={employeeStatusOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('employeeStatus', values)}
      />
      <DropdownFilter
        label='Voluntary and Company Requested'
        options={requestedOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('requested', values)}
      />
      <DropdownFilter
        label='All Employee Types'
        options={employeeTypeOptions}
        className="full-width mb-3"
        onChange={(values) => onChangeFilter('employeeType', values)}
      /> 
    </div>
  );
}

export default PolicyBenchmarkFilters;
