import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import './styles.scss';

function SurveySelect(props) {
  const [ selectedSurvey, setSelectedSurvey] = useState('international-2019');

  const onSelectSurvey = (key) => {
    setSelectedSurvey(key);
  }
  
  const surveys = [{
    label: 'Global Movement Study',
    value: 'international-2019',
  }];

  let surveyDropdownLabel = 'Select a Survey';
  if (selectedSurvey) surveyDropdownLabel = surveys.find(s => s.value === selectedSurvey).label;

  return (
    <Dropdown className="w-100 mb-3">
      <Dropdown.Toggle variant="primary" className="w-100 text-left dropdown-toggle">{surveyDropdownLabel}</Dropdown.Toggle>
      <Dropdown.Menu>
        {surveys.map((survey) => (
          <Dropdown.Item
            active={selectedSurvey === survey.value}
            eventKey={survey.value}
            key={survey.value}
            onSelect={onSelectSurvey}
          >
            {survey.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown> 
  );
}

export default SurveySelect;
