import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./util/history";

import "./App.scss";

import Admin from "./components/Admin";
import Applet from './components/Applet';
import Dashboard from "./components/Dashboard";
import Policies from "./components/Policies";
import PolicyDetail from "./components/PolicyDetail";
import Portal from "./components/Portal";
import SecuredRoute from "./components/SecuredRoute";


function App() {
  if (process.env.NODE_ENV === 'production') {
    return (
      <Router history={history}>
        <Switch>
          <SecuredRoute path="/home" component={Dashboard} />
          <SecuredRoute path="/admin" component={Admin} />
          <SecuredRoute path="/dashboard" component={Dashboard} />
          <SecuredRoute path="/explorer" component={Portal} />
          <SecuredRoute path="/policies/:id" component={PolicyDetail} />
          <SecuredRoute path="/policies" component={Policies} />
          <Route path="/applet" component={Applet} />
          <SecuredRoute path="/" component={Portal} />
        </Switch>
      </Router>
    );
  }
  return (
    <Router history={history}>
      <Switch>
        <SecuredRoute path="/home" component={Dashboard} />
        <Route path="/admin" component={Admin} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/explorer" component={Portal} />
        <Route path="/policies/:id" component={PolicyDetail} />
        <Route path="/policies" component={Policies} />
        <Route path="/applet" component={Applet} />
        <Route path="/" component={Portal} />
      </Switch>
    </Router>
  );
}

export default App;
