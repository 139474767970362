import React, { useEffect, useState } from 'react';
import {
  Link 
} from 'react-router-dom';

import {useAuth0} from "../Auth"

import {
  createNewPolicy,
  getPoliciesForUser
} from '../../services/policy.service';

import { isProd } from '../../config/api';

import Nav from '../Nav';
import PolicyItem from '../PolicyItem';

require('./styles.scss');

function Policies(props) {
  const [policies, setPolicies] = useState([]);
  const [isCreatingPolicy, setIsCreatingPolicy] = useState(false);

  const { user } = useAuth0();
  var userEmail = user && user.email ? user.email : null

  if (!isProd) userEmail = 'justin@maderalabs.com';

  useEffect(() => {
    getPoliciesForUser(userEmail)
      .then(policies => setPolicies(policies))
      .catch(err => console.log(err));   
  }, []);

  const onCreateNewPolicy = () => {
    if (isCreatingPolicy) return;
    setIsCreatingPolicy(true);
    createNewPolicy(userEmail)
      .then((policy) => {
        setIsCreatingPolicy(false);
        props.history.replace(`/policies/${policy._id}`);
      })
      .catch(err => console.log(err));
  }

  return (
    <div>
      <Nav />
      <div className="row policies">
        <div className="col-12 px-5 py-4">
          <h1 className="mb-4">My Policies</h1>
          {policies.length > 0 &&
            <div className="card-group">
                {policies.map((policy) => (
                  <Link to={`/policies/${policy._id}`}>
                    <PolicyItem policy={policy} />
                  </Link>
                ))}
                  <div className="policy-item add-policy-item" onClick={onCreateNewPolicy}>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <i className="fas fa-plus-circle mr-2"/>
                      <strong>New Policy</strong>
                    </div>
                  </div>
            </div>
          }
          {policies.length === 0 &&
            <div className="d-flex align-items-center justify-content-center p-5 m-5 flex-column">
              <h3 className="mb-4">Welcome to the Worldwide ERC Policy Builder</h3>
              <p className="w-50">
                Our policy builder allows you to build your policies while comparing
                to real-time benchmarks from your peers. Export your policies in one-click, 
                including assignment letters!
              </p>
              <button className="btn btn-primary px-5 py-3 mt-4" onClick={onCreateNewPolicy}>
                Create Your First Policy
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Policies;
