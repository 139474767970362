import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Auth0Provider } from './components/Auth';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './util/history';
import HttpsRedirect from 'react-https-redirect';

const audience = 'https://maderalabs.auth0.com/api/v2/';
const domain = 'maderalabs.auth0.com';
const clientId = 'SFR5Y2vD89rjmr8MCu6zQIhV2n7wH0LN';

const onRedirectCallback = appState => {
  history.replace(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <HttpsRedirect>
    <Auth0Provider
      domain={domain}
      client_id={clientId}
      redirect_uri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
