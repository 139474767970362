import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

require('./styles.scss');

const PolicyAttributes = ({isUpdatingPolicy, onUpdatePolicy, policy}) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!policy.title) {
      setIsEditing(true);
    }
  }, []); 

  useEffect(() => {
    if (!isUpdatingPolicy) setIsEditing(false);
  }, [isUpdatingPolicy]);

  const onFormSubmit = (values) => {
    onUpdatePolicy(values);
  }

  const initialValues = {
    title: policy.title,
    type: policy.type,
    description: policy.description,
  };

  const yupValidationSchema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string(),
  });

  const showEdit = isEditing || 
    (policy && policy.components && !policy.title); 
 
  return (
    <div>
      <Link to="/policies">
        <span>
          <i className="fad fa-angle-left mr-2" />
          Back to Policies
        </span>
      </Link>
    {!showEdit && policy.title &&
      <div className="mt-4">
        <h3 className="mb-3">{policy.title}</h3>
        <p>{policy.description}</p>
        <span onClick={() => setIsEditing(true)} className="small">
          <i className="fad fa-pencil mr-2" />Edit
        </span>
      </div>
    }
    {showEdit &&
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={yupValidationSchema}
        onSubmit={onFormSubmit}
      >
        <Form className="form policy-attributes">
          <label htmlFor="title">Policy Name</label>
          <Field as="input" name="title" className="form-control"/>
          <ErrorMessage name="title" component="div" /> 
          <label htmlFor="type">Domestic or International?</label>
          <Field name="type" as="select" placeholder="Please select" className="form-control">
            <option value="domestic">Domestic</option>
            <option value="international">International</option>
          </Field>
          <label htmlFor="description">Policy Description</label>
          <Field as="textarea" name="description" className="form-control"/>
          <ErrorMessage name="description" component="div" />
          <button type="submit" className="btn btn-success mt-3">Submit</button>
        </Form>
      </Formik>
      }
    </div>
  );
}

export default PolicyAttributes;
