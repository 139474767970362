import React from 'react';

// PolicyItems use Bootstrap cards to render
require('./styles.scss');

const PolicyItem = (props) => {
  const {
    title,
    description,
  } = props.policy;

  const prepDescription = (description) => {
    if (!description) return null;
    const maxLength = 100;
    if (description.length < maxLength) return description;
    return `${description.substring(0, maxLength)}...`;
  }

  return (
    <div className="policy-item card mr-3">
      <div className="card-body d-flex align-items-center flex-column justify-content-center text-center">
        <h3>{title || 'Untitled Policy'}</h3>
        <p>{prepDescription(description)}</p>
      </div>
    </div>
  );
}

export default PolicyItem;
