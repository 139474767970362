import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';

const colors = [
  '#005670',
  '#0091B9',
  '#0127C4',
  '#000B39',
  '#FFB300',
  '#FF8100',
  '#542A00',
  '#005670',
  '#0091B9',
  '#0127C4',
  '#000B39',
  '#FFB300',
  '#FF8100',
  '#542A00',
];

function ChartBar(props) {
  const {
    data,
    showAsSummed,
    showAsMulti,
    question,
  } = props; 

  const isShowIndividualData = props.showIndividualData;

  let chartData = [];
  const mode = props.calcMode;
  let bars;

  const summedData = {};
  let summedDataTotal = 0;

  if (showAsSummed) {
    for(const key in data) {
      const policyItem = data[key];
      policyItem.count.forEach((answerObjectForPolicyType) => {
        if (!summedData[answerObjectForPolicyType.policyType]){
          summedData[answerObjectForPolicyType.policyType] = 0;
        } 
        summedData[answerObjectForPolicyType.policyType] = 
        summedData[answerObjectForPolicyType.policyType] + answerObjectForPolicyType.count;;
      });
    }

    const summedDataTotal = Object.values(summedData).reduce((curr, accum) => curr + accum);

    for(const key in summedData) {
      chartData.push({
        name: key,
        value: Math.floor(summedData[key] / summedDataTotal * 100),
      });
    }

    bars = <Bar dataKey="value" fill={colors[0]} />
  }

  if (!showAsSummed) {
    for(var key in data){
      const records = {}
      var sum = 0

      const totalPolicies = data[key].count.reduce((x, y) => x + parseInt(y.count), 0);

      for(var policyType of data[key].count){
        if(isShowIndividualData && policyType.userPolicies.length === 0) continue
        sum = data[key].responseCountForType;

        //use employeeCount instead of count here, if switched 
        if (mode === 'policy') {
          records[policyType.policyType] = policyType.count;
        }
        if (mode === 'employee') {
          records[policyType.policyType] = policyType.employeeCount;
          sum = data[key].count.map((answer => answer.employeeCount)).reduce((x, y) => x + y, 0);
        }
      }

      for(var [k, count] of Object.entries(records)){
        let percentage = 0;
        let totalCount = count;

        if (sum > 0) {
          percentage = Math.floor((totalCount*100.0)/sum);
        } 
        if (totalPolicies <= 5) percentage = 0;
        records[k] = percentage;
      }

      const keyData = {
        name: data[key].option,
        ...records
      }

      chartData.push(keyData);
    }

    // we need to find the item in chartData with the most columns
    // then iterate just that one, to build the bars
    const policyTypes = [];
    chartData.forEach((column, columnIndex) => {
      // get the total count of values, summed , for sample size
      const totalColumnCount = Object.values(column).reduce((accum, currentValue, index) => {
        if (index === 0) return 0;
        return accum + parseInt(currentValue);
      }, 0); 

      // push the policy types for this data set into the array
      Object.keys(column).forEach((c, index) => {
        if (index === 0) return; 
        if (policyTypes.indexOf(c) === -1) {
          policyTypes.push(c);
        }
      });  
    });
    
    //Sort chartData 
    const chartOrder = [
      'Long term',
      'Short term',
      'Commuter',
      'Rotational',
      'Intra-regional',
      'Project based',
      'Global nomad/repeat expatriate',
      'Talent development',
      'Local plus',
      'Permanent or one-way transfer',
    ];

    chartData.sort((a,b) => {
      if (chartOrder.indexOf(a.name) > chartOrder.indexOf(b.name)) {
        return 1;
      }
      if (chartOrder.indexOf(a.name) < chartOrder.indexOf(b.name)) {
        return -1;
      }
      return 0;
    });

    bars = policyTypes.map((type, index) => (
      <Bar dataKey={type} stackId="a" fill={colors[index]} />
    ));

    //TODO: to show as multi, need to change the percentages
    // each data element should be divided by the total number of policies of the given type
    // think right now this is divided by total number of policies, not total of policy type?
    // really dunno, braindead, so this may make more sense later
        

    if (showAsMulti) { 
      bars = policyTypes.map((type, index) => (
        <Bar dataKey={type} fill={colors[index]} />
      ));
    }
  } 

  // Look thru chartData. If any policy types have under 5 responses, set their values as 0 (to hide the chart)  

  var isLongChart = Object.entries(chartData).length > 5

  const formatter = (value) => `${value}%`
  let toolTipFormatter = (value, name, props) => [`${Math.floor(value)}%`, name];

  if (showAsSummed) {
    toolTipFormatter = (value, name, props) => [
      `${value}%`, null
    ];
  }

  let domain = [0, 100];
  if (question.qid === 'Q5.2') domain = [0, 15]; //change the top of the Y Axis for policy count question

  return ( 
    <ResponsiveContainer width="100%" height={isLongChart ? 600 : 400}>
      <BarChart data={chartData}>
        {isLongChart ?
          <XAxis dataKey="name" interval={0} height={180} tick={<CustomizedAxisTick/>} />
          :
          <XAxis dataKey="name" />
        }
        
        <YAxis tickFormatter={formatter} domain={domain}/>
        <Tooltip formatter={toolTipFormatter} />
        <Legend />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}

const CustomizedAxisTick = (props) => {
  const {x, y, stroke, payload} = props;
  const [textA, textB] = splitWords(payload.value)
   return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{textA}</text>
      <text x={0} y={0} dy={32} textAnchor="end" fill="#666" transform="rotate(-35)">{textB}</text>
    </g>
  )
};

const splitWords = (str) => {
  if(str.length <= 20) return [str, ""]

  var words = str.split(" ")

  var count = Math.round(words.length/2)

  return [
  words.slice(0,count).join(" "),
  words.slice(count).join(" ")
  ]
}

export default ChartBar;
