import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import classNames from 'classnames';

import "react-toggle/style.css"

import PolicyComponent from '../PolicyComponent';
import { 
  enablePolicyComponentGroup,
  disablePolicyComponentGroup,
} from '../../services/policy.service';

import { getQuestions } from '../../services/question.service';

require('./styles.scss');

const PolicyComponentGroup = (props) => {
  const {
    policy,
    groupId,
    questions,
  } = props;

  const isGroupActiveForPolicy = 
    policy.components 
    && policy.components.includes(groupId);

  const [isGroupActive, setIsGroupActive] = useState(isGroupActiveForPolicy);
  const [groupQuestions, setGroupQuestions] = useState([]); 

  const getQuestionsForGroup = () => {
    getQuestions(questions, groupId).then(questions => setGroupQuestions(questions));
  }

  useEffect(() => {
    if (isGroupActive) {
      getQuestionsForGroup();
    }  
  }, []);

  if (Object.keys(policy).length === 0) return null; 
 
  const onChangePolicyComponentValue = (question, selection) => {
    props.onChangePolicyComponentValue(question, selection);
  }

  const onChangeToggle = () => {
    if (!isGroupActive) {
      getQuestionsForGroup();
      enablePolicyComponentGroup(policy._id, groupId);
    }
    if (isGroupActive) disablePolicyComponentGroup(policy._id, groupId);

    setIsGroupActive(!isGroupActive); 
  }

  const pgClasses = classNames(
    'd-flex',
    'align-items-center',
    'pg-head',
    'text-white',
    'w-100',
    'py-4',
    'p-3',
    'font-weight-bold',
    {
      'bg-secondary': !isGroupActive,
    }
  );

  const isRootAnswerYes = () => {
    if (groupQuestions.length > 0) {
      const rootAnswer = policy.answers.find(a => a.question === groupQuestions[0]._id);
      if (!rootAnswer) return false;
      return rootAnswer.option === groupQuestions[0].options.find(o => o.body === "Yes")._id;
    }
    return false;
  }
  
  return (
    <div className="policy-component-group row">
      <div className={pgClasses}>
        <span>
          {props.title}
        </span>
        <span className="ml-auto">
          <Toggle
            checked={isGroupActive}
            onChange={onChangeToggle}
          />
        </span>
      </div>
      {isGroupActive &&
        <div className="col-12"> 
          {groupQuestions.length > 0 &&
            <PolicyComponent
              policy={policy._id}
              question={groupQuestions[0]}
              onChangeValue={onChangePolicyComponentValue}
              filters={props.filters}
              answer={policy.answers.find(a => a.question === groupQuestions[0]._id)}
            />
          }
          {isRootAnswerYes() && groupQuestions.map((question, index) => {
            if (index === 0) return null;
            const answer = policy.answers.find(a => a.question === question._id); 
            return <PolicyComponent
              policy={policy._id}
              question={question}
              onChangeValue={onChangePolicyComponentValue}
              filters={props.filters}
              answer={answer}
            />
          })}
        </div>
      }
    </div>
  );
}

export default PolicyComponentGroup;
