import React, { useState } from 'react';

import AdminQuestionEdit from '../AdminQuestionEdit';
import AdminQuestionListItem from '../AdminQuestionListItem';
import Nav from '../Nav';

import { getQuestions } from '../../services/question.service';

function Admin(props) {
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});

  if (!questions.length) { 
    getQuestions()
      .then(questions => {
        const flattenedQuestions = [];
        questions.forEach(section => flattenedQuestions.push(section.questions));
        setQuestions([].concat.apply([], flattenedQuestions))
      })
      .catch(error => setError(error));
  }

  const updateQuestion = (updatedQuestion) => {
    const questionIndex = questions.findIndex(question => question._id === updatedQuestion._id);
    const updatedQuestions = [ ...questions ];
    updatedQuestions[questionIndex] = updatedQuestion;
    setQuestions(updatedQuestions);
  }

  return (
    <div>
      <Nav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-3">
            <h2>Admin</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            { questions.map(question => (
                <AdminQuestionListItem
                  key={question._id}
                  question={question}
                  onClick={(selectedQuestion) => setSelectedQuestion(selectedQuestion)}
                />
            ))}
          </div>
          <div className="col-7">
            <AdminQuestionEdit question={selectedQuestion} onSave={updateQuestion} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
