import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import DashboardItem from '../DashboardItem';
import Nav from '../Nav';
import './styles.scss';

const dashboardItems = [{
  title: 'Explore Benchmarking Data',
  description: 'Browse through thousands of data points to determine best practices amongst your industry peers',
  icon: 'fa-chart-bar',
  link: 'explorer',
}, {
  title: 'Manage Your Policies',
  description: 'Build, manage and share your policies in our interactive builder, with benchmarking data built in',
  icon: 'fa-file-alt',
}];

function Dashboard(props) {

  const goTo = route => { props.history.replace(`/${route}`); }

  const login = () => { props.auth.login(); }

  const logout = () => { props.auth.logout(); }

  useEffect(() => { 
    const { renewSession } = props.auth;
    if (localStorage.getItem('isLoggedIn') === true) {
      renewSession();
    }
  });

  const { isAuthenticated } = props.auth;

  return (
    <div>
      <Nav />
      { !isAuthenticated &&
        <button onClick={login}>Log in</button>
      }
      { isAuthenticated &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-11">
              <h1 className="py-3">Welcome to ERC Policy HQ</h1>
            </div>
          </div>
          <div className="row">
            {dashboardItems.map((item) => (
              <div className="col-12 col-md-4">
                <Link to={item.link}>
                  <DashboardItem
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
}

export default Dashboard;
