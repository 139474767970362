import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function TOSModal({hasAcceptedTerms, onAcceptTerms}) {
  return (
    <Modal show={!hasAcceptedTerms} size="lg">
      <Modal.Dialog size="lg" style={{margin: 0}}>
        <Modal.Header>
          <Modal.Title>
            <h3>Limited Use License Agreement – LOGIN VERSION</h3>
            <h2>Workforce Mobility Benchmarking Database</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Upon acceptance of this Agreement, Worldwide ERC®, Inc., (WERC) hereby grants you a
             LIMITED USE LICENSE (&quot;License&quot;) to the Workforce Mobility Benchmarking Database
             (“Database”). This Agreement sets forth the terms and conditions governing the use of the
             Database.
          </p>
          <p>You are granted a non-transferable, non-exclusive license to use the Database for research
             purposes only. As used herein, the definition of Research excludes uses of the Database to (i)
             perform contract research, (ii) produce products for general sale, or (iii) conduct research
             activities that result in any sale, lease, license, or transfer of the data or derivatives thereof.
          </p>
          <p>
             Any publication or presentation of the results of the research using the Database will duly
             acknowledge WERC as their source. In the event of a breach of this Agreement, WERC may
             seek all appropriate damages as well as attorney fees.
          </p>
          <p>
            This Agreement shall be governed by and interpreted in accordance with the laws of the
            Commonwealth of Virginia without effect to its conflict of laws provisions. Any disputes arising
            between the parties shall be brought before the United States District Court for the Eastern
            District of Virginia.
          </p>
          <p>
            This Agreement contains the entire agreement of the parties and may only be superseded,
            modified or amended if the amendment is made in writing and is signed by both parties.
          </p>
          <p>
            Do you agree to the terms and conditions of this Limited Use License?
          </p>
          <Button onClick={onAcceptTerms} size="lg">I accept</Button>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  )
}


export default TOSModal;

