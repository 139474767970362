import React from 'react';
import ProgramIndicator from '../ProgramIndicator';

function DataGridSummed(props) {  
  const {
    data,
  } = props;

  const summedData = {};

  for(const key in data) {
    const policyItem = data[key];
    policyItem.count.forEach((answerObjectForPolicyType) => {
      if (!summedData[answerObjectForPolicyType.policyType]){
        summedData[answerObjectForPolicyType.policyType] = 0;
      } 
      summedData[answerObjectForPolicyType.policyType] = 
      summedData[answerObjectForPolicyType.policyType] + answerObjectForPolicyType.count;;
    });
  } 

  let totalResponses = 0;

  for(const key in summedData) {
    totalResponses = totalResponses + summedData[key];
  }

  return (
    <table className="table table-striped mt-5">
      <thead>
        <tr>
          <th width="70%">
            <span className="text-secondary font-weight-light">
              (n = {totalResponses})
            </span>
          </th>
          <th>
          Percentage
          </th>
        </tr>
      </thead> 
      <tbody>
        {Object.keys(summedData).map((key) => (
          <tr>
            <td>{key}</td>
            <td>{Math.floor(summedData[key] / totalResponses * 100)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataGridSummed;
