import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../Auth";
import Unauthorized from "../Unauthorized"
import userRoles from "../../config/userRoles"

const SecuredRoute = ({ component: Component, path, ...rest }) => {
  console.log('auth0', useAuth0());
  const { loading, isAuthenticated, loginWithRedirect, user  } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  useEffect(() =>{
    if(!user) return
    var roles = user["https://www.worldwideerc.org/roles"] || []
    var canViewApp = false

    if(roles.includes(userRoles.GMDA) || roles.includes(userRoles.GMGRA)){
      canViewApp = true
    }
    
    setIsAuthorized(canViewApp)
  }, [user])

  const render = props => {
    if(!isAuthenticated) return null

    if(!isAuthorized) return <Unauthorized />

    return <Component {...props} />
  }

  return <Route path={path} render={render} {...rest} />;
};

export default SecuredRoute;
