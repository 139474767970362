const isProd = (process.env.NODE_ENV === 'production');
console.log('isProd', isProd);

let apiUrl = 'http://localhost:2109';

if (isProd) {
  apiUrl = 'https://erc-benchmarking-api.herokuapp.com';
}

module.exports = {
  apiUrl,
  isProd,
}
