import React, { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import Select from 'react-select';

import BarChart from '../BarChart';
import DataGrid from '../DataGrid';
import DataGridSummed from '../DataGridSummed';
import DropdownFilter from '../DropdownFilter';

import  { getFilterOptions } from '../../services/admin.service';
import  { getQuestionData } from '../../services/question.service';
import userRoles from "../../config/userRoles"

import {useAuth0} from "../Auth"

require('./styles.scss');

function BenchmarkDetail(props) {
  const {
    activeQuestion,
    isApplet,
  } = props;

  const [isLoadingData, setIsLoadingData] = useState(false);

  const [industrySelection, setIndustrySelection] = useState(null);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [isShowingIndividualData, setIsShowingIndividualData] = useState(false);
  const [programSelection, setProgramSelection] = useState(null);
  const [programOptions, setProgramOptions] = useState([]);
  const [questionData, setQuestionData] = useState({});
  const [questionMeta, setQuestionMeta] = useState({});
  const [revenueSelection, setRevenueSelection] = useState(null);
  const [revenueOptions, setRevenueOptions] = useState([]);
  const [sizeSelection, setSizeSelection] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [HQSelection, setHQSelection] = useState(null);
  const [HQOptions, setHQOptions] = useState([]);
  const [jobLevelSelection, setJobLevelSelection] = useState(null);
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [employeeStatusSelection, setEmployeeStatusSelection] = useState(null);
  const [employeeStatusOptions, setEmployeeStatusOptions] = useState([]);
  const [requestedSelection, setRequestedSelection] = useState(null);
  const [requestedOptions, setRequestedOptions] = useState([]);
  const [employeeTypeSelection, setEmployeeTypeSelection] = useState(null);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);

  const [canUseFilters , setCanUseFilters ] = useState(false)
  const [calcMode, setCalcMode] = useState('policy');

  const onChangeIndustry = industry => setIndustrySelection(industry);
  const onChangeRevenue = revenue => setRevenueSelection(revenue);
  const onChangeSize = size => setSizeSelection(size);
  const onChangeHQ = hq => setHQSelection(hq);
  const onChangeJobLevel = jobLevel => setJobLevelSelection(jobLevel);
  const onChangeEmployeeStatus = employeeStatus => setEmployeeStatusSelection(employeeStatus);
  const onChangeRequested = requested => setRequestedSelection(requested);
  const onChangeEmployeeType = employeeType => setEmployeeTypeSelection(employeeType);
  
  const onChangeCalcMode = option => setCalcMode(option.value);

  const { user } = useAuth0();

  useEffect(() => {
    if (programOptions.length === 0) {
      getFilterOptions()
        .then(opts => {
          setIndustryOptions(opts.industry);
          setProgramOptions(opts.programType)
          setRevenueOptions(opts.revenue);
          setSizeOptions(opts.size);
          setHQOptions(opts.hq);
          setJobLevelOptions(opts.jobLevel);
          setEmployeeStatusOptions(opts.employeeStatus);
          setRequestedOptions(opts.requested);
          setEmployeeTypeOptions(opts.employeeType);
        })
        .catch(e => console.log(e));
    }
    if (process.env.NODE_ENV === 'production' && !isApplet) {
      var roles = user["https://www.worldwideerc.org/roles"] || []
      setCanUseFilters(roles.includes(userRoles.GMDA))
    }

    const query = queryString.parse(window.location.search);

    if (query.forceHideFilters) {
      setCanUseFilters(false);
    }
    if (query.forceShowFilters) {
      setCanUseFilters(true);
    }
    if (isApplet) {
      setCanUseFilters(true);
    }
  }, []);

  useEffect(() => {
    const filters = {};
    if (!activeQuestion._id) return;

    setIsLoadingData(true);

    if (industrySelection) filters.industry = industrySelection.map(i => i.value);
    if (sizeSelection) filters.size = sizeSelection.map(s => s.value);
    if (revenueSelection) filters.revenue = revenueSelection.map(r => r.value);
    if (HQSelection) filters.hq = HQSelection.map(r => r.value);
    if (jobLevelSelection) filters.jobLevel = jobLevelSelection.map(r => r.value);
    if (employeeStatusSelection) filters.employeeStatus = employeeStatusSelection.map(r => r.value);
    if (requestedSelection) filters.requested = requestedSelection.map(r => r.value);
    if (employeeTypeSelection) filters.employeeType = employeeTypeSelection.map(r => r.value);
    
    var userEmail = user && user.email ? user.email : null

    getQuestionData(activeQuestion._id, filters, userEmail)
      .then((questionData) => {
        setQuestionData(questionData.data);
        setQuestionMeta(questionData.meta);
        setIsLoadingData(false);
      })
      .catch((e) => console.log('Error', e));
  }, [
    HQSelection,
    activeQuestion,
    employeeStatusSelection,
    employeeTypeSelection,
    industrySelection,
    jobLevelSelection,
    requestedSelection,
    programSelection,
    revenueSelection,
    sizeSelection,
  ]); 

  const resetFilters = () => {
    setIndustrySelection(null);
    setProgramSelection(null)
    setRevenueSelection(null);
    setSizeSelection(null);
    setHQSelection(null);
    setJobLevelSelection(null);
    setEmployeeStatusSelection(null);
    setRequestedSelection(null);
    setEmployeeTypeSelection(null);
  }

  const toggleIndividualData = () => {
    setIsShowingIndividualData(!isShowingIndividualData);
  }

  let totalResponses;
  let totalEmployees;
  
  if (Object.keys(questionData).length > 0) {  
    totalResponses = Object.keys(questionData)
      .map(key => {
        if (questionData[key].count) {
          return questionData[key].count.reduce((a, c) => a + parseInt(c.count), 0)
        }
      })
      .reduce((acc, curr) => acc + curr);  
  }

  const sortHeadcountOptions = (options) => {
    options.sort((optionA, optionB) => {
      const optionAInt = parseInt(optionA.label.split('-')[0]);
      const optionBInt = parseInt(optionB.label.split('-')[0]);

      if (optionAInt < optionBInt) return -1
      return 1; 
    });
  }

  const formatHeadcountOptions = (options) => {
    const formatNumber = (num) => {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    return options.map((option) => {
      option.label = formatNumber(option.label);
      return option;
    });
  };

  const calcOptions = [{
    value: 'policy', label: 'By Total Number of Policies',
  }, {
    value: 'employee', label: 'By Total Number of Employees Affected',
  }];

  const calcOptionsLimited = [{
    value: 'policy', label: 'By Total Number of Policies',
  },{
    label: 'Available only to Premier Subscribers',
    options: [{
      value: 'employee',
      label: 'By Total Number of Employees Affected',
      isDisabled: true,
    }],
  }];

  const questionsAsSummed = [
    'Q3.3',
    'Q3.4_1',
    'Q3.4_2',
    'Q3.4_3',
    'Q3.4_4',
    'Q3.4_5',
    'Q3.4_6',
    'Q3.4_7',
    'Q3.4_8',
    'Q3.4_9',
    'Q3.4_10',
    'Q3.5',
    'Q5.2',
  ];

  const questionsAsMulti = [
    'Q7.3_1',
    'Q10.3_1',
    'Q15.2_1',
    'Q16.2_1',
    'Q15.3_1',
    'Q23.3_1',
    'Q29.2_1',
    'Q30.3_1',
    'Q38.2_1',
    'Q44.2_1',
    'Q44.5_1',
  ];

  const showSummedData = questionsAsSummed.includes(activeQuestion.qid);
  const showMultiData = questionsAsMulti.includes(activeQuestion.qid);

  return (
    <div className="py-2 px-3">
      <div className="d-flex align-items-center mb-4">
        <h2>{ activeQuestion.benchmarkingBody }</h2>
        <span className="ml-auto d-flex align-items-center" style={{zIndex: '9999'}}>
          Show data calculated by
          <Select
            value={calcOptions.find(o => o.value === calcMode)}
            options={(canUseFilters) ? calcOptions : calcOptionsLimited}
            onChange={onChangeCalcMode}
            className="calculation-select ml-3"
          />
        </span>
      </div>
      <div className="data-container bg-white p-3">
        <div className="d-flex align-items-center">
          <div className="mr-auto d-flex align-items-center mb-4 w-100 filters position-relative"> 
            {!canUseFilters &&
              <div className="filter-scrim position-absolute d-flex align-items-center justify-content-center flex-column">  
                <h3>
                  <i className="fad fa-lock mr-3"/>Upgrade for custom interactive benchmarking data
                </h3>
                <p>Filtering is an advanced feature, only available to our Global Movement Database Access subscribers.{" "}
                <strong><a href="https://ams.worldwideerc.org/eWeb/DynamicPage.aspx?Action=Add&ObjectKeyFrom=1A83491A-9853-4C87-86A4-F7D95601C2E2&WebCode=ProdDetailAdd&DoNotSave=yes&ParentObject=CentralizedOrderEntry&ParentDataObject=Invoice%20Detail&ivd_formkey=69202792-63d7-4ba2-bf4e-a0da41270555&ivd_cst_key=4083818f-3d83-4f99-bf5e-6ef2f9cb7916&ivd_cst_ship_key=4083818f-3d83-4f99-bf5e-6ef2f9cb7916&ivd_prc_prd_key=730C55B0-46D2-483A-9A9A-F0B97E375DB7">Upgrade Now <i className="fas fa-angle-right"/></a></strong></p>
              </div>
            }
            <DropdownFilter
              label='All Industries'
              onChange={onChangeIndustry}
              options={industryOptions}
              selected={industrySelection}
            /> 
            <DropdownFilter
              label='All Revenues'
              onChange={onChangeRevenue}
              options={revenueOptions}
              selected={revenueSelection}
            />
            <DropdownFilter
              label='All Headcounts'
              onChange={onChangeSize}
              options={sizeOptions}
              selected={sizeSelection}
              requiresSort
              sortMethod={sortHeadcountOptions}
              formatter={formatHeadcountOptions}
            />
            {!isApplet &&
              <Fragment>
                <DropdownFilter
                  label='All HQ Locations'
                  onChange={onChangeHQ}
                  options={HQOptions}
                  selected={HQSelection}
                />
                <DropdownFilter
                  label='All Job Levels'
                  onChange={onChangeJobLevel}
                  options={jobLevelOptions}
                  selected={jobLevelSelection}
                />
                <DropdownFilter
                  label='All Employee Statuses'
                  onChange={onChangeEmployeeStatus}
                  options={employeeStatusOptions}
                  selected={employeeStatusSelection}
                />
                <DropdownFilter
                  label='Voluntary & Company Requested'
                  onChange={onChangeRequested}
                  options={requestedOptions}
                  selected={requestedSelection}
                />
                <DropdownFilter
                  label='All Employee Types'
                  onChange={onChangeEmployeeType}
                  options={employeeTypeOptions}
                  selected={employeeTypeSelection}
                />
              </Fragment>
            }
          </div>
          <div className="d-flex align-items-center">
            { !isShowingIndividualData && !isApplet &&
              <i className="fad fa-toggle-off mr-2 fa-2x ind-toggle" onClick={toggleIndividualData} />
            }
            { isShowingIndividualData && !isApplet &&
              <i className="fad fa-toggle-on mr-2 fa-2x ind-toggle" onClick={toggleIndividualData} />
            }
            { isApplet &&
              <i className="fad fa-toggle-off mr-2 fa-2x ind-toggle" />
            }
            <span>Show my data</span>
          </div>
        </div>
        { totalResponses > 0 &&
          <Fragment>
            {!isLoadingData &&
              <BarChart
                data={questionData}
                questionMeta={questionMeta}
                showIndividualData={isShowingIndividualData}
                calcMode={calcMode}
                showAsSummed={showSummedData}
                showAsMulti={showMultiData}
                question={activeQuestion}
              />
            }
            {isLoadingData && 
              <div className="d-flex align-items-center justify-content-center w-100 h-100" style={{minHeight: '400px'}}>
                <i className="fad fa-spinner fa-spin fa-3x" />
              </div>
            }
            {!showSummedData &&
              <DataGrid 
                questionOptions={activeQuestion.options}
                data={questionData}
                questionMeta={questionMeta}
                showIndividualData={isShowingIndividualData}
                totalResponses={totalResponses}
                calcMode={calcMode}
                showAsMulti={showMultiData}
              />
            }
            {showSummedData &&
              <DataGridSummed
                data={questionData}
                questionMeta={questionMeta}
                showIndividualData={isShowingIndividualData}
              />
            }
          </Fragment>
        }
        { totalResponses === 0 &&
          <div className="mt-5 text-center">
            <i className="fad fa-box-open fa-4x mb-3" />
            <h4>Sorry, there are no results for this combination of filters.</h4>
            <button className="btn btn-link" onClick={resetFilters}>Reset filters</button>
          </div>
        }
      </div>
    </div>
  );
}

export default BenchmarkDetail;
