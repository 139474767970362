import React, { useEffect, useState } from 'react';
import BenchmarkDetail from '../BenchmarkDetail';

import  { getAppletQuestion } from '../../services/admin.service';

function Applet() {
  const [question, setQuestion] = useState({});

  useEffect(() => {
    getAppletQuestion()
      .then(question => setQuestion(question))
      .catch(e => console.log(e));
  }, []);

  return (
    <div className="pt-3"> 
      <BenchmarkDetail activeQuestion={question} isApplet />
    </div>
  );
};

export default Applet;
