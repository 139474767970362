import React, { Fragment, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';

import { getQuestionData } from '../../services/question.service';

const colors = [
  '#005670',
  '#0091B9',
  '#0127C4',
  '#000B39',
  '#FFB300',
  '#FF8100',
  '#542A00',
  '#005670',
  '#0091B9',
  '#0127C4',
  '#000B39',
  '#FFB300',
  '#FF8100',
  '#542A00',
];

function PolicyComponentBenchmarks(props) {
  const [data, setData] = useState({ data: {}});
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    setIsLoadingData(true);
    //if (!props.isVisible) return;

    getQuestionData(props.question._id, props.filters)
      .then(data => {
        setIsLoadingData(false);
        setData(data);
      })
      .catch(err => console.log(err));
  }, [props.filters]);

  const {
    question,
  } = props;

  const onLoadBenchmarkData = () => {
    setIsLoadingData(true);
    getQuestionData(props.question._id) 
      .then(data => {
        setIsLoadingData(false);
        setData(data)
      })
      .catch(err => console.log(err));
  }

  const summedData = {};
  const chartData = [];

  if (Object.keys(data.data).length > 0) {
    for(const key in data.data) { //TODO: clean this shit up
      const policyItem = data.data[key];
      policyItem.count.forEach((answerObjectForPolicyType) => {
        if (!summedData[answerObjectForPolicyType.policyType]){
          summedData[answerObjectForPolicyType.policyType] = 0;
        } 
        summedData[answerObjectForPolicyType.policyType] = 
        summedData[answerObjectForPolicyType.policyType] + answerObjectForPolicyType.count;;
      });
    }
  }

  let totalResponses = 0;

  for(const key in summedData) {
    totalResponses = totalResponses + parseInt(summedData[key]); 
  }

  for(const key in summedData) {
    chartData.push({
      name: key,
      value: summedData[key] / totalResponses * 100,
    });
  }
  
  const formatter = (value) => `${value}%`
  const toolTipFormatter = (value, name, props) => [`${Math.floor(value)}%`, "Amount"];

  const legendPayload = chartData.map((d, idx) => ({ value: d.name, color: colors[idx] }));

  return (
    <div>
      {data && !isLoadingData && 
        <Fragment>
          {totalResponses > 0 &&
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={chartData} layout="vertical">
                <XAxis type="number" tickFormatter={formatter}/>
                <YAxis type="category" dataKey="name" tick={false} />
                <Legend payload={legendPayload} />
                <Tooltip formatter={toolTipFormatter} />
                <Bar dataKey="value" fill={colors[0]}>
                  {
                    chartData.map((entry, index) => {
                      return <Cell fill={colors[index]} />
                    })
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          }
          {totalResponses === 0 &&
            <div className="mt-5 text-center">
              <i className="fad fa-box-open fa-4x mb-3" />
              <h4>Sorry, there are no results for this combination of filters.</h4>
            </div>            
          }
        </Fragment>
      }
      {isLoadingData &&
        <div>
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <i className="fad fa-spinner fa-spin fa-3x" />
          </div>
        </div>
      }
      {!data && !isLoadingData &&
        <div onClick={onLoadBenchmarkData}>
          Load Benchmark Data
        </div>
      }
    </div>
  );
}

export default PolicyComponentBenchmarks;
