import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { updateQuestionOption } from '../../services/question.service';

require('./styles.scss');

function AdminQuestionOption({questionId, option}) { 
  const [ isEditing, setIsEditing ] = useState(false);
  const [ optionValue, setOptionValue ] = useState(option.body);

  const onSaveOption = () => {
    updateQuestionOption(questionId, option._id, optionValue)
      .then((updatedQuestion) => {
        setIsEditing(false);
      })
      .catch((err) => {
        //TODO: handle error
      });
  }

  const onChangeOptionValue = (e) => {
    setOptionValue(e.target.value);
  }

  return (
    <div className="d-flex align-items-center"> 
      {!isEditing &&
        <span>
          {optionValue}
          <i className="fad fa-pencil ml-2" onClick={() => setIsEditing(true)} />
        </span>
      }
      {isEditing &&
        <Form className="d-flex align-items-center">
          <Form.Group className="mb-0"> 
            <Form.Control value={optionValue} onChange={onChangeOptionValue} />
          </Form.Group>
          <Button variant="primary" className="mx-2" onClick={onSaveOption}>Save</Button>
          <Button variant="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
        </Form>
      }
    </div>
  );
}

export default AdminQuestionOption;
