import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AdminQuestionOption from '../AdminQuestionOption';

import { updateQuestion } from '../../services/question.service';

require('./styles.scss');

function AdminQuestionEdit({question, onSave}) {
  const [ body, setBody ] = useState(question.body);
  const [ category, setCategory ] = useState(question.category);

  useEffect(() => {
    setBody(question.body);
    setCategory(question.category);
  }, [question]);

  const {
    options = [],
    qid,
  } = question;

  const saveQuestion = () => {
    const editedQuestion = {
      body,
      category,
    }
    updateQuestion(question._id, editedQuestion)
      .then((updatedQuestion) => {
        onSave(updatedQuestion);
      })
      .catch((err) => {
        console.log('Error', err);
        //TODO: error handling
      })
  }

  return (
    <div className="sticky-top"> 
      <strong>Qualtrics Question ID</strong>
      <p>{ qid }</p>
      <Form>
        <Form.Group>
          <Form.Label>
            Question Body
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Question Category
          </Form.Label>
          <Form.Control
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Form.Group>
          { options.map(option => (
            <AdminQuestionOption option={option} key={option._id} questionId={question._id} /> )
          )}
      </Form>
      <div className="mt-3">
        <Button variant="primary" onClick={saveQuestion}>Save</Button>
      </div>
    </div>
  );
}

export default AdminQuestionEdit;
