// this transforms the grouped questions into a format that react-autosuggest 
// needs for display sections and titles

export const transformDataForSearch = questions => {
  const transformedData = [];
  questions.forEach(section => {
    transformedData.push({
      _id: section._id,
      title: section.questions[0].populatedCategory[0].title,
      questions: section.questions,
    });
  });
  return transformedData;
}
