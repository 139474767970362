import React, { useEffect, useState } from 'react';

import Nav from '../Nav';
import PolicyAttributes from '../PolicyAttributes';
import PolicyBenchmarkFilters from '../PolicyBenchmarkFilters';
import PolicyComponentGroup from '../PolicyComponentGroup';

import { updatePolicyComponent } from '../../services/policy.service';

import {
  exportPolicy,
  getPolicy,
  getPolicyChunks,
  updatePolicy
} from '../../services/policy.service';

function PolicyDetail(props) {
  const [isUpdatingPolicy, setIsUpdatingPolicy] = useState(false);
  const [isPolicyLoaded, setIsPolicyLoaded] = useState(false);
  const [policy, setPolicy] = useState({});
  const [benchmarkFilters, setBenchmarkFilters] = useState({});
  const [policyChunks, setPolicyChunks] = useState([]);

  useEffect(() => {
    getPolicy(props.match.params.id)
      .then((policy) => {
        setPolicy(policy);
        setIsPolicyLoaded(true);
      })
      .catch(err => console.log(err));

    getPolicyChunks()
      .then(policyTemplate => setPolicyChunks(policyTemplate.chunks))
      .catch(err => console.log(err));

  }, [props.match.params.id]);

  if (!isPolicyLoaded) return null;

  const onChangePolicyComponent = (question, selection) => { 
    updatePolicyComponent(question._id, {
      option: selection.value,
      policy: policy._id,
    })
      .then((updatedPolicyComponent) => { 
        getPolicy(props.match.params.id)
          .then((policy) => {
            setPolicy(policy);
            setIsPolicyLoaded(true);
          })
          .catch(err => console.log(err)); 
      })
      .catch(e => console.log('Error updating policy component', e));
    }

  const onUpdatePolicy = (values) => {
    setIsUpdatingPolicy(true);
    updatePolicy(policy._id, values)
      .then((updatedPolicy) => {
        setPolicy({...policy, ...updatedPolicy});
        setIsUpdatingPolicy(false);
      })
      .catch(err => console.log(err));
  }

  const onChangeBenchmarkFilters = (filters) => {
    setBenchmarkFilters(filters);
  }

  const onExportPolicy = () => {
    exportPolicy(policy._id);    
  }

  return (
    <div>
      <Nav includesPolicyExport onExportPolicy={onExportPolicy} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 bg-light px-5 pt-4">
            <div className="sticky-top">
              <PolicyAttributes policy={policy} onUpdatePolicy={onUpdatePolicy} isUpdatingPolicy={isUpdatingPolicy} />
              <hr />
              <PolicyBenchmarkFilters onChangeFilters={onChangeBenchmarkFilters} />
            </div>
          </div>
          <div className="col-9">
            {policyChunks.map((policyChunk) => {
              return (
                <PolicyComponentGroup
                  groupId={policyChunk.id}
                  policy={policy}
                  title={policyChunk.label}
                  questions={policyChunk.questions}
                  onChangePolicyComponentValue={onChangePolicyComponent}
                  filters={benchmarkFilters}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyDetail;
