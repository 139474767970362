import React from 'react';
import ProgramIndicator from '../ProgramIndicator';

function DataGrid(props) {  
  const dataByPolicyType = {};
  const policyTypeNames = [];

  const mode = props.calcMode;

  const isShowIndividualData = props.showIndividualData;

  Object.keys(props.data).forEach((answerObject) => {
    let totalPoliciesOfType = 0;

    //if (!props.showAsMulti) {
    // totalPoliciesOfType = props.data[answerObject].count.reduce((accum, item) => {
    //    return accum + parseInt(item.count);
    //  }, 0);
    //}

    totalPoliciesOfType = props.data[answerObject].responseCountForType;

    const totalEmployeesForType = props.data[answerObject].count.reduce((accum, item) => {
      return accum + parseInt(item.employeeCount);
    }, 0);

    for(var policyTypeRow of props.data[answerObject].count){
      if(isShowIndividualData && policyTypeRow.userPolicies.length ===0) continue

      if(!dataByPolicyType[policyTypeRow._id]) dataByPolicyType[policyTypeRow._id] = [];

      let answerObjectCount = policyTypeRow.count;
      if (mode === 'employee') answerObjectCount = policyTypeRow.employeeCount;

      dataByPolicyType[policyTypeRow._id].push({
        [answerObject]: answerObjectCount,
        userPolicies: policyTypeRow.userPolicies,
        policyType: props.data[answerObject].policyType,
        totalPoliciesOfType,
        totalEmployeesForType,
      });

      policyTypeNames.push({
        policyTypeId: policyTypeRow._id,
        policyTypeName: policyTypeRow.policyType, 
      });
    }
  });

  const findPolicyTypeName = (policyTypeId) => policyTypeNames.find((pType) => {
    if (pType.policyTypeId === policyTypeId) return true;
  });

  const policyOrder = [
    'Long term',
    'Short term',
    'Commuter',
    'Rotational',
    'Intra-regional',
    'Project based',
    'Global nomad/repeat expatriate',
    'Talent development',
    'Local plus',
    'Permanent or one-way transfer',
  ];

  return Object.keys(dataByPolicyType).map((policyTypeAnswers, index) => {
    const totalCountForPolicyType = dataByPolicyType[policyTypeAnswers]
      .map((answerObject) => {
        for (const prop in answerObject) {
          return answerObject[prop];
        }
      })
      .reduce((accum, currVal) => accum + currVal);

    return (
      <table className="table table-striped mt-5">
        <thead>
          <tr>
            <th width="70%">
              {findPolicyTypeName(policyTypeAnswers).policyTypeName}
              <span className="text-secondary font-weight-light">
                (n = {totalCountForPolicyType})
              </span>
            </th>
            <th>
            Percentage
            </th>
          </tr>
        </thead> 
        <tbody>
          {dataByPolicyType[policyTypeAnswers]
              .sort((a,b) => {
                if (policyOrder.indexOf(a.policyType) > policyOrder.indexOf(b.policyType)) {
                  return 1;
                }
                if (policyOrder.indexOf(a.policyType) < policyOrder.indexOf(b.policyType)) {
                  return -1;
                }
                return 0;    
              })
              .map((answer) => {
            let percentage = 0;
            if (answer.totalPoliciesOfType > 0 && mode === 'policy') {
              percentage = Math.floor(Object.values(answer)[0] / answer.totalPoliciesOfType * 100);
            }

            if (answer.totalEmployeesForType > 0 && mode === 'employee') {
              percentage = Math.floor(Object.values(answer)[0] / answer.totalEmployeesForType * 100);
            }
            return (
              <tr>
                <td className="d-flex">
                  <span>    
                    {answer.policyType}
                  </span>
                  {answer.userPolicies.length > 0 &&
                    <ProgramIndicator policies={answer.userPolicies} />
                  }
                </td>
                {answer.totalPoliciesOfType <= 5 &&
                  <td>Insufficient data to display</td>
                }
                {answer.totalPoliciesOfType > 5 &&
                  <td>{percentage}%</td>
                }
              </tr>   
            );
          })}
        </tbody>
      </table>
  )});
}

export default DataGrid;
